import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import CompanyBar from '../components/company-bar';
import Cta from '../components/cta';
import Footer from '../components/footer';

const StyledIndex = styled.div``;

const IndexPage = () => (
  <Layout>
    <SEO />
    <StyledIndex>
      <Hero />
      <CompanyBar />
      <Cta />
      <Footer />
    </StyledIndex>
  </Layout>
);

export default IndexPage;
