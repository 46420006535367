import React from 'react';
import styled from 'styled-components';
import claytonLogo from '../images/logos/Clayton_Corporate_White.svg';
import claytonHomesLogo from '../images/logos/Clayton_Homes_W__1.svg';
import crosslandLogo from '../images/logos/CrosslandHomes_W.svg';
import freedomLogo from '../images/logos/FreedomHomes_W.svg';
import homeFirstLogo from '../images/logos/HomeFirst_Logo_White.svg';
import internationalLogo from '../images/logos/InternationalHomes_W.svg';
import luvHomesLogo from '../images/logos/LuvHomes_W_TransparentLUV.svg';
import oakwoodLogo from '../images/logos/OakwoodHomes_Horiz_W.svg';
import scotbiltLogo from '../images/logos/ScotBilt_White.svg';
import silvertonLogo from '../images/logos/Silverton_logo-vert_WHITE.svg';
import truLogo from '../images/logos/TruValue_W.svg';
import vanderbiltLogo from '../images/logos/Vanderbilt_Custom.svg';
import ContentWrapper from './content-wrapper';

const StyledCompanyBar = styled.div`
  background-color: ${props => props.theme.colors.blue};
  padding: 0.5rem 1.5rem;

  img {
    width: 100%;
    margin: 1rem 0;
  }
`;

const CompanyBar = () => {
  return (
    <StyledCompanyBar>
      <ContentWrapper>
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-6">
            <div className="row align-items-center">
              <div className="col-4">
                <img src={claytonLogo} alt="Clayton Logo" />
              </div>
              <div className="col-4">
                <img src={vanderbiltLogo} alt="Vanderbilt Logo" />
              </div>
              <div className="col-4">
                <img src={silvertonLogo} alt="Silverton Logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="row align-items-center">
              <div className="col-4">
                <img src={homeFirstLogo} alt="HomeFirst Logo" />
              </div>
              <div className="col-4">
                <img src={luvHomesLogo} alt="Luv Homes Logo" />
              </div>
              <div className="col-4">
                <img src={freedomLogo} alt="Freedom Homes Logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="row align-items-center">
              <div className="col-4">
                <img src={truLogo} alt="Tru Value Logo" />
              </div>
              <div className="col-4">
                <img src={claytonHomesLogo} alt="Clayton Homes Logo" />
              </div>
              <div className="col-4">
                <img src={oakwoodLogo} alt="Oakwood Homes Logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="row">
              <div className="col-4">
                <img src={internationalLogo} alt="International Logo" />
              </div>
              <div className="col-4">
                <img src={crosslandLogo} alt="Crossland Logo" />
              </div>
              <div className="col-4">
                <img src={scotbiltLogo} alt="Scotbilt Logo" />
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </StyledCompanyBar>
  );
};

export default CompanyBar;
