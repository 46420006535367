import React from 'react';
import styled from 'styled-components';
import equalHousingLogo from '../images/logos/Equal_Housing_Logo.svg';

const StyledFooter = styled.footer`
  padding: 2rem 1rem;
  background-color: ${props => props.theme.colors.grayDark};
  color: white;
  text-align: center;
  font-size: 0.875rem;
  .text {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .links {
    a {
      color: white;
      text-decoration: underline;
      margin: 0 0.5rem;
    }
  }

  .logo {
    img {
      width: 4rem;
    }
  }

  .osano-link {
    a {
      text-decoration: underline;
      cursor: pointer;
    }

    margin-bottom: 1rem;
  }
`;

const Footer = props => {
  return (
    <StyledFooter>
      <div className="text">
        © 2010-{new Date().getFullYear()} Vanderbilt Mortgage and Finance, Inc.
        All rights reserved
      </div>
      <div className="links">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.claytonhomes.com/privacy/"
        >
          Privacy
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.vmf.com/legal-privacy/"
        >
          Legal
        </a>
      </div>
      <div className="osano-link">
        <a onClick={() => window?.Osano?.cm?.showDrawer('osano-cm-dom-info-dialog-open')}>
          Do Not Sell or Share My Personal Information
        </a>
      </div>
      <div className="logo">
        <img src={equalHousingLogo} alt="Equal Housing Lender" />
      </div>
    </StyledFooter>
  );
};

Footer.propTypes = {};

export default Footer;
