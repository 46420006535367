import React from 'react';
import styled from 'styled-components';
import ContentWrapper from './content-wrapper';

const StyledHero = styled.div`
  .top-bar {
    background-color: ${props => props.theme.colors.blue};
    height: 1.5rem;
  }

  .hero {
    position: relative;
    .headline {
      color: ${props => props.theme.colors.blue};
      font-size: 2rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 1.4;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }

    .sub-headline {
      color: ${props => props.theme.colors.blue};
      font-size: 1.125rem;
      font-weight: 300;
      text-decoration: underline;
      text-transform: uppercase;
    }

    .body {
      color: ${props => props.theme.colors.blue};
      line-height: 1.4;
      font-weight: 500;
      padding-right: 1rem;
      margin-bottom: 4rem;

      ol {
        margin: 0;
        padding-left: 1rem;
        li {
          padding-left: 1rem;
          margin-bottom: 0.5rem;
          font-weight: 300;
        }
      }
    }

    .bg-grey-float {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 46%;
      background-color: ${props => props.theme.colors.grayLight};

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .bg-grey-small {
      position: relative;
      .downward-triangle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;

        @media screen and (max-width: 992px) {
          display: block;
        }
      }

      @media screen and (max-width: 992px) {
        padding-top: 2rem;
        background-color: ${props => props.theme.colors.grayLight};
      }
    }

    .company-list {
      line-height: 1.4;
      color: ${props => props.theme.colors.grayDark};
      .title {
        font-size: 1rem;
        font-weight: bold;
      }
      ol {
        padding-left: 1rem;
        li {
          padding-left: 1rem;
          margin-bottom: 0.5rem;
          font-weight: 300;
        }
      }
    }
  }
`;

const Hero = () => {
  return (
    <StyledHero>
      <div className="top-bar"></div>
      <div className="hero">
        <div className="bg-grey-float">
          <svg
            id="arrow-right"
            height="100%"
            width="48"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            shapeRendering="geometricPrecision"
          >
            <polygon fill="white" points="0,100 100,50 0,0" />
          </svg>
        </div>
        <ContentWrapper>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h1 className="headline">Welcome to Opt Out Today</h1>
                <h6 className="sub-headline">Information Sharing Opt Out</h6>
                <div className="body">
                  <p>
                    To opt out of certain information sharing, you will need
                    your Opt Out ID or Application ID (found on your copy of the
                    Privacy Notice you received during the application process)
                    and the last name used on your application.
                  </p>
                  <p>
                    If you choose to opt out, none of our affiliates will share
                    your personal information:
                  </p>
                  <ol>
                    <li>
                      With our affiliates for their everyday business purposes
                      <br />
                      (limited to information about your creditworthiness)
                    </li>
                    <li>With our affiliates for them to market to you, and</li>
                    <li>With nonaffiliates for them to market to you.</li>
                  </ol>
                </div>
              </div>
              <div className="bg-grey-small col-lg-5">
                <div className="downward-triangle">
                  <svg
                    id="arrow-down"
                    height="48"
                    width="100%"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    shapeRendering="geometricPrecision"
                  >
                    <polygon fill="white" points="50,100 100,0 0,0" />
                  </svg>
                </div>
                <div className="company-list py-5">
                  <h3 className="title">
                    This website is for customers of the following companies:
                  </h3>
                  <ol>
                    <li>
                      Vanderbilt Mortgage and Finance, Inc. (NMLS #1561) also
                      doing business as Silverton Mortgage
                    </li>
                    <li>HomeFirst Agency, Inc.</li>
                    <li>CMH Homes, Inc.</li>
                    <li>Clayton Properties Group, Inc.</li>
                    <li>Wimbledon Realty, LLC</li>
                    <li>Wimbledon Properties, LLC</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </StyledHero>
  );
};

Hero.propTypes = {};

export default Hero;
