import axios from 'axios';

/**
 *  Opt out a customer
 * @param {OptOutModel} OptOutModel
 * @return {Promise<string>} Confirmation - Confirmed Opt Out ID
 */
export default async function lookupOrOptout({
  lastName,
  optOutId,
  appId,
  recaptcha,
}) {
  // Step 1: If opt out ID is present, try and opt out
  // the customer
  if (optOutId) {
    const confirmation = await setOptOut(optOutId, recaptcha);
    addToDataLayer({ event: 'optout-by-optoutid-success' });
    return confirmation;
  }
  // Step 2: If opt out ID is not present but an app ID is,
  // lookup the customer.
  else if (appId && lastName) {
    // Step 3: If the customer is found and a valid OptOutId
    // is returned, set them as opted out. Otherwise return an error
    const foundOptOutId = await lookupAppId(appId, lastName, recaptcha);
    const confirmation = await setOptOut(foundOptOutId, recaptcha);
    addToDataLayer({ event: 'optout-by-lookup-success' });
    return confirmation;
  }
  // Finally, throw an error if neither optOutId or appId is present
  else {
    throw new Error(
      `There was an error with the information provided 
      by the service: lookupOrOptOut`
    );
  }
}

/****************
 * UTILITY
 ****************/

/**
 * @typedef {Object} AppSettings
 * @property {String} baseApiUrl - The url of of the API
 */

/**
 * @typedef {Object} OptOutModel
 * @property {string} lastName - Last name of customer
 * @property {string} optOutId - Opt Out Id
 * @property {string} appId - Application Id
 * @property {string} recaptcha - Verification key from Recaptcha
 */

/**
 * Retrieve the base  settings for the API including POST url
 * @return {Promise<AppSettings>} optOutId Opt out id associated with the application
 */
async function getAppSettings() {
  try {
    const { data } = await axios.get('/appSettings.json');
    return data;
  } catch (e) {
    throw new Error('There was error with the api: getAppSettings');
  }
}

/**
 *
 * @param {String} optOutId
 * @return {Promise<String>} Success message
 */
async function setOptOut(optOutId, recaptcha) {
  try {
    const { baseApiUrl } = await getAppSettings();

    const { data } = await axios.get(baseApiUrl, {
      params: {
        Method: 'SetOptedOut',
        AccessCode: 'A',
        OptOutId: optOutId,
        Recaptcha: recaptcha,
      },
    });

    if (data && (data.PassFail === 'p' || data.PassFail === 'P')) {
      return `The information you provided has successfully been opted out.`;
    } else {
      console.log('DATA: ', data);
      console.log('There was error with the api: setOptOut: data');
      throw new Error(errorMessage());
    }
  } catch (e) {
    console.log(e);
    console.log('There was error with the api: setOptOut');
    throw new Error(errorMessage());
  }
}

/**
 * Look up the optOutId bassed on an application or loan id
 * @param {String} appId Appliation Id
 * @param {String} lastName Last name of applicant
 * @return {Promise<String|null>} Opt Out Id
 */
async function lookupAppId(appId, lastName, recaptcha) {
  try {
    const { baseApiUrl, passPhrase } = await getAppSettings();

    const { data: appData } = await axios.get(baseApiUrl, {
      params: {
        Method: 'Lookup',
        AccessCode: 'A',
        PassPhrase: passPhrase,
        AppId: appId,
        Recaptcha: recaptcha,
      },
    });

    const appOptOutId = parseLookupDataForOptOutId(appData, lastName);

    // If lookup results and last name is equal, return opt out id
    if (appOptOutId) {
      return appOptOutId;
    }

    // If no, try again with loan number
    const { data: loanData } = await axios.get(baseApiUrl, {
      params: {
        Method: 'Lookup',
        AccessCode: 'A',
        PassPhrase: passPhrase,
        LoanNo: appId,
      },
    });

    const loanOptOutId = parseLookupDataForOptOutId(loanData, lastName);

    // If lookup results and last name is equal, return opt out id
    if (loanOptOutId) {
      return loanOptOutId;
    }

    console.log(
      'There was error with the api: lookupAppId - No Opt Out ID Found'
    );
    throw new Error(errorMessage());
  } catch (e) {
    console.log(e);
    console.log(
      'There was error with the api: lookupAppId - Error making API calls'
    );
    throw new Error(errorMessage());
  }
}

function errorMessage() {
  return 'We didn’t find a match for that information. Try again or call us for help at 1-877-826-5310';
}

function validateLookupApiResponse(lookupResponse) {
  return (
    lookupResponse &&
    lookupResponse.results &&
    lookupResponse.results.DATA &&
    lookupResponse.results.DATA.length &&
    lookupResponse.results.COLUMNS &&
    lookupResponse.results.COLUMNS.length
  );
}

function parseLookupDataForOptOutId(lookupResponse, lastName) {
  // Check if response is valid
  if (!validateLookupApiResponse(lookupResponse)) {
    return null;
  }

  // Parse data from response
  const { DATA: lookupData, COLUMNS: columns } = lookupResponse.results;
  const data = columns.reduce(
    (prev, col, idx) => ({
      ...prev,
      [col]: lookupData[0][idx],
    }),
    {}
  );
  // Ensure name (somewhat) matches
  if (
    lastName &&
    data.OPTNAME &&
    data.OPTNAME.toUpperCase().indexOf(lastName.toUpperCase()) < 0
  ) {
    console.log('Invalid Last Name');
    return null;
  }

  return data.MST_OPTOUT_ID ? data.MST_OPTOUT_ID : null;
}

function addToDataLayer(event) {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}
