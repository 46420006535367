import { useFormik } from 'formik';
import React, { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import styled from 'styled-components';
import lookupOrOptout from '../util/services';
import ContentWrapper from './content-wrapper';
import Spinner from './spinner';
import { getRecaptchaKey } from '../util/config';

const StyledCta = styled.div`
  background-color: ${props => props.theme.colors.grayLight};
  padding: 2rem 0;

  .header-text {
    color: ${props => props.theme.colors.grayDark};
    text-align: center;
    font-weight: 300;
    a {
      text-decoration: underline;
      color: ${props => props.theme.colors.grayDark};
    }
  }

  .weight-700 {
    font-weight: 700;
  }

  .form-section {
    margin: 0 auto 2rem;
    max-width: 760px;

    .error-messages {
      color: ${props => props.theme.colors.red};
      min-height: 2rem;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
    }
    input {
      width: 100%;
      border: 1px solid ${props => props.theme.colors.grayMedium};
      outline: none;
      box-shadow: none;
      line-height: 2;
      margin: 0.5rem 0;
      padding: 0.25rem 0.75rem;

      :disabled {
        opacity: 0.5;
      }

      &.error {
        border-color: ${props => props.theme.colors.red};
      }
    }

    .divider {
      text-align: center;
      margin: 0 0 1.5rem;
      text-transform: uppercase;
      color: ${props => props.theme.colors.grayMedium};
      font-size: 0.875rem;
      font-weight: 500;
      .gray-bg {
        padding: 0.125rem 0.5rem;
        background-color: ${props => props.theme.colors.grayLight};
      }
      ::after {
        content: '';
        display: block;
        margin-top: -0.625rem;
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.colors.grayMedium};
      }
    }

    .form-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      label {
        flex: 42%;
      }
      .divider {
        flex: 1 1 auto;
        margin: 0 0.5rem;
      }

      @media screen and (max-width: 992px) {
        display: block;

        .divider {
          margin: 1rem 0 1.5rem;
        }
      }
    }

    .recaptcha {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      min-height: 3rem;
      .recaptcha-error {
        text-align: center;
        font-size: 0.875rem;
        color: ${props => props.theme.colors.red};
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      button[type='submit'] {
        min-width: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-align: center;
        border: none;
        outline: none;
        padding: 0.75rem 2rem;
        background-color: ${props => props.theme.colors.blue};
        color: white;
        text-transform: uppercase;
        font-weight: 900;
        border-radius: 0.25rem;
        transition: all 225ms linear;
        :disabled {
          opacity: 0.5;
        }

        :hover {
          transform: translateY(-1%) scale(101%);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .success-block {
      min-height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => props.theme.colors.blue};
      .title {
        font-weight: 900;
        font-size: 2rem;
        line-height: 1;
      }
      .message {
        font-weight: 700;
        font-size: 1.5rem;
      }

      .checkmark {
        margin-right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        stroke-width: 4;
        stroke: white;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px ${props => props.theme.colors.blue};
        animation: fill 0.4s ease-in-out 0.4s forwards,
          scale 0.3s ease-in-out 0.9s both;
      }

      .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: ${props => props.theme.colors.blue};
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }

      .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      }

      @keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes scale {
        0%,
        100% {
          transform: none;
        }
        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      @keyframes fill {
        100% {
          box-shadow: inset 0px 0px 0px 30px ${props => props.theme.colors.blue};
        }
      }
    }
  }
`;

const validate = values => {
  const errors = {};
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.optOutId && !values.appId) {
    errors.optOutOrAppId = 'Required';
  }
  if (!values.recaptcha) {
    errors.recaptcha = 'Required';
  }
  return errors;
};

const Cta = props => {
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      lastName: '',
      optOutId: '',
      appId: '',
      recaptcha: '',
    },
    validate,
    onSubmit: (values, { setStatus }) => {
      setStatus('');
      return lookupOrOptout(values)
        .then(response => {
          setSuccess(true);
          setStatus(response);
        })
        .catch(error => {
          setSuccess(false);
          setStatus(error);
        });
    },
  });

  const showErrors =
    (formik.errors.lastName && formik.touched.lastName) ||
    (formik.errors.optOutOrAppId &&
      (formik.touched.optOutId || formik.touched.appId));

  const allTouched =
    formik.touched.lastName &&
    (formik.touched.optOutId || formik.touched.appId) &&
    formik.touched.recaptcha;

  return (
    <StyledCta>
      <ContentWrapper>
        <div className="container-fluid">
          <div className="header-text">
            <p>
              Also if you choose to opt out, and if you have a joint account
              with someone else, your opt out choice will apply to everyone on
              your account.
            </p>
            <p>
              If you agree with these opt out consequences, please enter your{' '}
              <span className="weight-700">last name</span> and either your{' '}
              <span className="weight-700">Opt Out ID</span> <u>or</u>{' '}
              <span className="weight-700">Application ID</span> in the fields
              below, and then select the "Opt Out" button.
            </p>
          </div>
          <div className="form-section">
            <div className="error-messages">
              {formik.status && !success && `${formik.status}`}
              {showErrors && formik.status && !success && (
                <div style={{ marginBottom: '.5rem' }} />
              )}
              {showErrors &&
                'In order to successfully opt you out, we need your last name AND either an Application ID or an Opt Out ID'}
            </div>
            {!success && (
              <form onSubmit={formik.handleSubmit}>
                <fieldset disabled={formik.isSubmitting}>
                  <label htmlFor="lastName">
                    Last Name
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className={
                        formik.errors.lastName &&
                        formik.touched.lastName &&
                        'error'
                      }
                    />
                  </label>
                  <div className="divider">
                    <span className="gray-bg">And</span>
                  </div>
                  <div className="form-row">
                    <label htmlFor="optOutId">
                      Opt Out Id
                      <input
                        id="optOutId"
                        name="optOutId"
                        type="text"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.optOutId}
                        className={
                          formik.errors.optOutOrAppId &&
                          (formik.touched.optOutId || formik.touched.appId) &&
                          'error'
                        }
                      />
                    </label>
                    <div className="divider">
                      <span className="gray-bg">Or</span>
                    </div>
                    <label htmlFor="appId">
                      Application ID / Loan # (Silverton Only)
                      <input
                        id="appId"
                        name="appId"
                        type="text"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.appId}
                        className={
                          formik.errors.optOutOrAppId &&
                          (formik.touched.optOutId || formik.touched.appId) &&
                          'error'
                        }
                      />
                    </label>
                  </div>
                  <div className="recaptcha">
                    <Recaptcha
                      sitekey={getRecaptchaKey()}
                      render="explicit"
                      theme="light"
                      type="image"
                      verifyCallback={response => {
                        formik.setFieldValue('recaptcha', response);
                        formik.setFieldTouched('recapthca', true);
                      }}
                      onloadCallback={() => {}}
                    />
                    {formik.touched.recaptcha && formik.errors.recaptcha && (
                      <div className="recaptcha-error">
                        Recaptcha is a required field
                      </div>
                    )}
                  </div>
                  <div className="submit">
                    <button
                      type="submit"
                      disabled={!formik.isValid && allTouched}
                    >
                      Opt Out
                      {formik.isSubmitting && (
                        <Spinner
                          size="1.75rem"
                          style={{ margin: '0 0 0 .5rem' }}
                        />
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            )}

            {success && formik.status && (
              <div className="success-block">
                <h3 className="title">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  Success!
                </h3>
                <p className="message">{formik.status}</p>
              </div>
            )}
          </div>
          <div className="header-text">
            <p>
              If you cannot locate your information or are having difficulty
              opting out please contact us at{' '}
              <a href="tel:18778265310">1-877-826-5310</a>.
            </p>
          </div>
        </div>
      </ContentWrapper>
    </StyledCta>
  );
};

Cta.propTypes = {};

export default Cta;
