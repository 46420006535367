import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSpinner = styled.div`
  display: inline-block;
  svg path,
  svg rect {
    fill: ${props => (props.color ? props.color : '#ffffff')};
  }
`;

const Spinner = ({ size, color, ...props }) => {
  return (
    <StyledSpinner {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={size}
        height={size}
        viewBox="0 0 50 50"
      >
        <path
          fill="#000"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </StyledSpinner>
  );
};

Spinner.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Spinner;
